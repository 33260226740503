import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: '登录',
        meta: {
            title: '材搬配后台管理系统'
        },
        component: () => import('../views/Login/Login.vue')
    },
    //下面路径映射分了children和菜单的分级无关，只是说明下面的所有路径映射都是再/首页中的，所以写道了childern里
    {
        path: '/',
        name: '首页',
        meta: {
            title: '材搬配后台管理系统'
        },
        component: () => import('../views/Home.vue'),
        children: [
            //对应刚进入首页或点击管理员头像或点击左上角后切换回的当前管理员信息页面
            {
                path: '/bindex/admininfo',
                name: '管理员信息',
                component: () => import('../views/admininfo/managerInfo.vue')
            },
            
            {
                path: '/bindex/index',
                name: '首页',
                component: () => import('../views/index/index.vue'),
            },
            {
                path: '/questions/questionlist',
                name: '工单管理',
                component: () => import('../views/questions/questionlist.vue'),
            },
             //平台配置
             {
                path: '/configuration/configuration',
                name: '平台配置',
                component: () => import('../views/configuration/configuration.vue')
            },
            {
                path: '/configuration/fenconfig',
                name: '分公司配置',
                component: () => import('../views/configuration/fenconfig.vue')
            },
            {
                path: '/member/member',
                name: '会员等级',
                component: () => import('../views/configuration/member/member.vue'),
            },
            {
                path: '/payment/payment',
                name: '支付参数',
                component: () => import('../views/configuration/payment/payment.vue'),
            },
            //用户管理
            {
                path: '/usermanage/usermanage',
                name: '全站管理员管理',
                component: () => import('../views/usermanage/usermanage.vue'),
            },
             // 管理员管理
             {
                path: '/usermanaget/usermanaget',
                name: '管理员管理',
                component: () => import('../views/usermanage/usermanagetwo.vue'),
            },
             // 管理员send管理
             {
                path: '/usermanage/sendusermanage',
                name: '管理员订单通知',
                component: () => import('../views/usermanage/sendusermanage.vue'),
            },
            //商家管理
            {
                path: '/fenbusinessmanage/fenbusinessmanage',
                name: '分公司管理',
                component: () => import('../views/businessmanage/fenbusinessmanage.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/fenbusinessdetail/fenbusinessdetail',
                name: '分公司详情',
                component: () => import('../views/businessmanage/fenbusinessdetail.vue'),
                meta: {isfan:true }
            },
            {
                path: '/businessmanage/businessmanage',
                name: '商家管理',
                component: () => import('../views/businessmanage/businessmanage.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/zxbusinessmanage/zxbusinessmanage',
                name: '装修公司管理',
                component: () => import('../views/businessmanage/zxbusinessmanage.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/businesspaymanage/businesspaymanage',
                name: '收款账号设置',
                component: () => import('../views/businessmanage/businesspaymanage.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/businesssh/businesssh',
                name: '合作机构审核',
                component: () => import('../views/businessmanage/businesssh.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/businessrz/businessrz',
                name: '认证商户管理',
                component: () => import('../views/businessmanage/businessrz.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/businessdetail/businessdetail',
                name: '机构详情',
                component: () => import('../views/businessmanage/businessdetail.vue'),
                meta: {isfan: true,isfan:true }
            },
            {
                path: '/zxbusinessdetail/zxbusinessdetail',
                name: '装修公司详情',
                component: () => import('../views/businessmanage/zxbusinessdetail.vue'),
                meta: {isfan: true }
            },
            {
                path: '/businessitemdetail/businessitemdetail',
                name: '机构信息修改',
                component: () => import('../views/businessmanage/businessitemdetail.vue'),
            },
            {
                path: '/zxbusinessitemdetail/zxbusinessitemdetail',
                name: '装企机构信息修改',
                component: () => import('../views/businessmanage/zxbusinessitemdetail.vue'),
            },
            //商家分类管理
            {
                path: '/companyCategory/companyCategory',
                name: '商户类型设置',
                component: () => import('../views/companyCategory/companyCategory.vue'),
            },
            // 商品分类管理页面
            {
                path: '/goodsCategory/goodsCategory',
                name: '商品分类管理',
                component: () => import('../views/goodsCategory/goodsCategory.vue'),
            },
            // 供应商管理页面
            {
                path: '/supplier/supplier',
                name: '供应商管理',
                component: () => import('../views/supplier/supplier.vue'),
                meta: {keepAlive: true }
            },
            // 供应商详情
            {
                path: '/supplierdetail/supplierdetail',
                name: '供应商详情',
                component: () => import('../views/supplier/supplierdetail.vue'),
                meta: {isfan: true }
            },
             // 供应商详情
             {
                path: '/supplieritemdetail/supplieritemdetail',
                name: '供应商信息修改',
                component: () => import('../views/supplier/supplieritemdetail.vue'),
                meta: {isfan: true }
            },
            // 品牌管理
            {
                path: '/brand/brand',
                name: '品牌管理',
                component: () => import('../views/brand/brand.vue'),
            },
            // 广告管理
            {
                path: '/advertising/advertising',
                name: '广告管理',
                component: () => import('../views/advertising/advertising.vue'),
            },
            // 库房管理
            {
                path: '/warehouse/warehouse',
                name: '库房管理',
                component: () => import('../views/warehouse/warehouse.vue'),
            },
            // 系列管理
            {
                path: '/series/series',
                name: '系列管理',
                component: () => import('../views/series/series.vue'),
            },
             // 规格管理
             {
                path: '/specification/specification',
                name: '规格管理',
                component: () => import('../views/specification/specification.vue'),
            },
            // 颜色管理
            {
                path: '/color/color',
                name: '颜色管理',
                component: () => import('../views/color/color.vue'),
            },
              //单位管理
            {
            path: '/unit/unit',
            name: '单位管理',
            component: () => import('../views/unit/unit.vue'),
            },
            // 商品管理
            {
            path: '/goodsmanage/goodsmanage',
            name: '商品管理',
            component: () => import('../views/goodsmanage/goodsmanage.vue'),
            meta: {keepAlive: true }
            },
            // 活动管理
            { 
                path: '/activity/activity',
                name: '特价活动配置',
                component: () => import('../views/activity/activity.vue'),
                meta: {keepAlive: true }
            },
            //  活动商品管理
            {
                path: '/evengoods/evengoods',
                name: '活动商品管理',
                isshow:true,
                component: () => import('../views/activity/evengoods/evengoods.vue'),
                meta: {isfan: true }
            },
             // 福利商品 
             {
                path: '/welfaregoods/welfaregoods',
                name: '商品福利',
                isshow:true,
                component: () => import('../views/welfaregoods/welfaregoods.vue'),
            },
            // 工长管理
            {
                path: '/foreman/foreman',
                name: '工长管理',
                component: () => import('../views/foreman/foreman.vue'),
            },
            // centralized 集采管理
            {
                path: '/centralized/centralized',
                name: '集采管理',
                component: () => import('../views/centralized/centralized.vue'),
            },
            // driver 司机管理
            {
                path: '/driver/driver',
                name: '司机管理',
                component: () => import('../views/driver/driver.vue'),
            },
             // post 订单管理
             {
                path: '/post/post',
                name: '订单管理',
                component: () => import('../views/post/post.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/dxgpost/dxgpost',
                name: '待修改订单',
                component: () => import('../views/post/dxgpost.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/fzpost/fzpost',
                name: '分账订单',
                component: () => import('../views/post/fzpost.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/post/recycledpost',
                name: '订单回收站',
                component: () => import('../views/post/recycledpost.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/payinfos/payinfos',
                name: '支付信息',
                component: () => import('../views/payinfos/payinfos.vue'),
            },
            {
                path: '/rzpost/rzpost',
                name: '认证支付信息',
                component: () => import('../views/post/rzpost.vue')
            },
              // address 地址管理
              {
                path: '/address/address',
                name: '地址管理',
                component: () => import('../views/address/address.vue'),
            },
              // address 地址详情
              {
                path: '/addressdetail/addressdetail',
                name: '地址详情',
                component: () => import('../views/address/addressdetail.vue'),
                meta: {isfan: true }
            },
            //  // deliver 发货详情管理
            //  {
            //     path: '/deliver/deliver',
            //     name: '发货详情管理',
            //     component: () => import('../views/deliver/deliver.vue'),
            // },
             // role 角色管理
             {
                path: '/role/role',
                name: '岗位配置',
                component: () => import('../views/role/role.vue'),
            },
             // menu 菜单管理
             {
                path: '/menu/menu',
                name: '系统菜单管理',
                component: () => import('../views/menu/menu.vue'),
            },
             // often 用户追踪
             {
                path: '/often/often',
                name: '用户访问追踪',
                component: () => import('../views/often/often.vue'),
            },
             // shipments 已发货单据
             {
                path: '/shipments/shipments',
                name: '已出库订单统计',
                component: () => import('../views/shipments/shipments.vue'),
                meta: {keepAlive: true }
            },
            // shipments 发货详情信息管理
            {
                path: '/shipdetail/shipdetail',
                name: '发货详情',
                component: () => import('../views/shipments/shipdetail/shipdetail.vue'),
                meta: {isfan: true }
            },
            {
                path: '/sendinfos/senddetail',
                name: '配送单详情',
                component: () => import('../views/sendinfos/senddetail.vue'),
                meta: {isfan: true }
            },
            {
                path: '/unloadpost/unloadpost',
                name: '线下订单审核',
                component: () => import('../views/post/unloadpost/unloadpost.vue'),
                meta: {keepAlive: true }
            },
            // unsendpost 待发货订单
            {
                path: '/unsendpost/unsendpost',
                name: '待分仓订单',
                component: () => import('../views/post/unsendpost/unsendpost.vue'),
                meta: {keepAlive: true }
            },
            // sendpost 订单发货
            {
                path: '/sendpost/sendpost',
                name: '订单发货',
                component: () => import('../views/post/sendpost/sendpost.vue'),
                meta: {isfan:true}
            },
             {
                path: '/sendpost/lcsendpost',
                name: '临采订单发货',
                component: () => import('../views/post/sendpost/lcsendpost.vue'),
                meta: {isfan:true}
            },
             // unsendpost 订单详情
             {
                path: '/postdetail/postdetail',
                name: '订单详情',
                component: () => import('../views/post/postdetail/postdetail.vue'),
                meta: {keepAlive: true ,isfan:true}
            },
             // client 用户管理
             {
                path: '/client/client',
                name: '平台会员管理',
                component: () => import('../views/client/client.vue'),
            },
            // stock 入库管理
            {
                path: '/stock/stock',
                name: '其他采购审核',
                component: () => import('../views/stock/stock.vue'),
                meta: {keepAlive: true }
            },
            // stock 采购单据锁定
            {
                path: '/stockhistory/stockhistory',
                name: '采购价格审核',
                component: () => import('../views/stock/stockhistory/stockhistory.vue'),
                meta: {keepAlive: true }
            },
             // stock 采购单据锁定
             {
                path: '/stockhistory/stockthhistory',
                name: '无单退货核价',
                component: () => import('../views/stock/stockhistory/stockthhistory.vue'),
                meta: {keepAlive: true }
            },
             // stock 采购入库确认
             {
                path: '/stocksure/stocksure',
                name: '入库单据核实',
                component: () => import('../views/stock/stocksure.vue'),
                meta: {keepAlive: true }
            },
             {
                path: '/stockDetail/stockDetail',
                name: '库存单详情',
                component: () => import('../views/stock/stockDetail/stockDetail.vue'),
                meta: {isfan: true }
            },
            {
                path: '/outstock/outstock',
                meta: {keepAlive: true },
                name: '销售订单退货',
                component: () => import('../views/outstock/outstock.vue'),
            },
             {
                path: '/outpostlist/outpostlist',
                name: '退货单管理',
                meta: {keepAlive: true },
                component: () => import('../views/outstock/outpostlist.vue'),
            },
            {
                path: '/outpost/dshoutpostlist',
                name: '退货单审核',
                meta: {keepAlive: true },
                component: () => import('../views/outpost/dshoutpostlist.vue'),
            },
            {
                path: '/outpost/dqroutpostlist',
                name: '退货单确认',
                meta: {keepAlive: true },
                component: () => import('../views/outpost/dqroutpostlist.vue'),
            },
            {
                path: '/outpostc/outpostc',
                name: '退厂单管理',
                meta: {keepAlive: true },
                component: () => import('../views/outstock/outpostc.vue'),
            },
            {
                path: '/outpost/dshoutpostc',
                name: '退厂单审核',
                meta: {keepAlive: true },
                component: () => import('../views/outpost/dshoutpostc.vue'),
            },
            {
                path: '/outpost/dqroutpostc',
                name: '退厂单确认',
                meta: {keepAlive: true },
                component: () => import('../views/outpost/dqroutpostc.vue'),
            },
            {
                path: '/outstock/outpostcprice',
                name: '退厂单价格审核',
                meta: {keepAlive: true },
                component: () => import('../views/outstock/outpostcprice.vue'),
            },
            {
                path: '/outstock/outpostcpricedetail',
                name: '退厂单价格详情',
                meta: {isfan: true },
                component: () => import('../views/outstock/outpostcpricedetail.vue'),
            },
            {
                path: '/outstock/outpostdetail',
                name: '退货单详情',
                meta:{isfan:true},
                component: () => import('../views/outstock/outpostdetail.vue'),
            },
            {
                path: '/outstock/outpostdetailc',
                name: '退厂单详情',
                meta:{isfan:true},
                component: () => import('../views/outstock/outpostdetailc.vue'),
            },
            {
                path: '/outpostpay/outpostpay',
                name: '销售退款审核',
                meta:{keepAlive:true},
                component: () => import('../views/outstock/outpostpay.vue'),
            },
            {
                path: '/outpostpay/outpostpayc',
                name: '退厂单收款',
                meta: {keepAlive: true },
                component: () => import('../views/outstock/outpostpayc.vue'),
            },
             {
                path: '/outstock/emitretrundetail',
                name: '退货单详情',
                meta:{isfan:true},
                component: () => import('../views/outstock/emitretrundetail.vue'),
            },
            {
                path: '/outstock/emitretrundetailc',
                name: '退厂单详情',
                meta:{isfan:true},
                component: () => import('../views/outstock/emitretrundetailc.vue'),
            },
            {
                path: '/outstock/surepostdetail',
                name: '确认退货',
                meta:{isfan:true},
                component: () => import('../views/outstock/surepostdetail.vue'),
            },
            {
                path: '/outstock/surepostdetailreback',
                name: '退货恢复',
                meta:{isfan:true},
                component: () => import('../views/outstock/surepostdetailreback.vue'),
            },
            {
                path: '/outstock/surepostdetailc',
                name: '确认退厂',
                meta:{isfan:true},
                component: () => import('../views/outstock/surepostdetailc.vue'),
            },
             // zxpost 装修公司订单管理
             {
                path: '/zxpost/zxpost',
                name: '装修公司订单管理',
                component: () => import('../views/zxpost/zxpost.vue'),
                meta: {keepAlive: true }
            },
             // zxpost 装修公司订单审核管理
             {
                path: '/zxunload/zxunload',
                name: '装修公司订单审核管理',
                component: () => import('../views/zxpost/unloadpost/unloadpost.vue'),
                meta: {keepAlive: true }
            },
             //  管理装修公司订单
             {
                path: '/salesmanzxpost/salesmanzxpost',
                name: '装修公司订单审核管理',
                component: () => import('../views/salesman/zxpost.vue'),
                meta: {isfan: true }
            },
             // zxunpay 装修公司订单详情
             {
                path: '/zxunpay/zxunpay',
                name: '装修公司未结算订单',
                component: () => import('../views/zxpost/unpaypost/unpaypost.vue'),
                meta: {keepAlive: true }
            },
             // zxpost 装修公司订单详情
             {
                path: '/zxdetail/zxdetail',
                name: '装修公司订单详情',
                component: () => import('../views/zxpost/postdetail/postdetail.vue'),
                meta: {isfan: true }
            },
             // postdata 装修公司订单统计
             {
                path: '/zxpostdata/zxpostdata',
                name: '装修公司订单统计',
                component: () => import('../views/zxpost/postdata/postdata.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/postinfos/postinfos',
                name: '订单详情',
                component: () => import('../views/post/postinfos.vue'),
                meta: {isfan: true }
            },

             // quotation 报价单
             {
                path: '/quotation/quotation',
                name: '报价单',
                component: () => import('../views/quotation/quotation.vue'),
                meta: {keepAlive: true }
            },
              // quotation 报价单详情
              {
                path: '/quotationdetail/quotationdetail',
                name: '报价单详情',
                component: () => import('../views/quotation/quotationdetail/quotationdetail.vue'),
                meta: {isfan: true }
            },
             // partner 合伙人审核
             {
                path: '/partner/partner',
                name: '合伙人审核',
                component: () => import('../views/partner/partner.vue'),
            },
            // 业务员管理
            {
                path: '/salesman/salesman',
                name: '业务员管理',
                component: () => import('../views/salesman/salesman.vue'),
            },
             // 短信验证信息
            {
                path: '/notehistory/notehistory',
                name: '短信验证信息',
                component: () => import('../views/notehistory/notehistory.vue'),
            },
             // 装修公司业务员管理
             {
                path: '/zxsalesman/zxsalesman',
                name: '业务员管理',
                component: () => import('../views/salesman/zxsalesman.vue'),
            },
             // 装修公司集采商品管理
             {
                path: '/centralized/centralizedgood',
                name: '集采商品管理',
                component: () => import('../views/centralized/centralizedgood.vue'),
            },
            // 装修公司入库管理
            {
                path: '/zxstock/zxstock',
                name: '入库管理',
                component: () => import('../views/zxstock/stock.vue'),
                meta: {keepAlive: true }
            },
            // 采购单据填报
            {
                path: '/cgdstock/cgdstock',
                name: '采购单据填报',
                component: () => import('../views/stock/cgdstock.vue'),
                meta: {keepAlive: true }
            },
             // 联营代管入库
             {
                path: '/lystock/lystock',
                name: '联营入库单统计',
                component: () => import('../views/stock/lystock.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/stock/lystockrk',
                name: '联营入库单',
                component: () => import('../views/stock/lystockrk.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/stock/dgstock',
                name: '代管入库单统计',
                component: () => import('../views/stock/dgstock.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/stock/dgstockrk',
                name: '代管入库单',
                component: () => import('../views/stock/dgstockrk.vue'),
                meta: {keepAlive: true }
            },
            // 装修公司入库管理详情
            {
                path: '/zxstockDetail/zxstockDetail',
                name: '入库管理详情',
                component: () => import('../views/zxstock/stockDetail/stockDetail.vue'),
                meta: {isfan: true }
            },
             // 装修公司入库管理详情
             {
                path: '/zxsDetail/zxsDetail',
                name: '入库详情',
                component: () => import('../views/zxstock/zxDetail/zxDetail.vue'),
                meta: {isfan: true }
            },
            // 供应商入库管理
            {
                path: '/ghstock/ghstock',
                name: '申请产品入库',
                meta: {keepAlive: true},
                component: () => import('../views/ghstock/stock.vue'),
            },
            // 供应商入库管理
            {
                path: '/ghstockDetail/ghstockDetail',
                name: '供应商入库详情',
                component: () => import('../views/ghstock/stockDetail/stockDetail.vue'),
                meta: {isfan: true }
            },
             // 入库审核
             {
                path: '/stocksh/stocksh',
                name: '入库信息',
                component: () => import('../views/stock/stocksh/stocksh.vue'),
                meta: {isfan: true ,keepAlive: true}
            },
            {
                path: '/stock/stockinfos',
                name: '采购单信息',
                component: () => import('../views/stock/stockinfos.vue'),
                meta: {isfan: true ,keepAlive: true}
            },
            {
                path: '/stock/restockdetail',
                name: '入库单详情',
                component: () => import('../views/stock/restockdetail.vue'),
                meta: {isfan: true ,keepAlive: true}
            },
            // 创建发货单
            {
                path: '/fensendpost/fensendpost',
                name: '创建发货单',
                component: () => import('../views/post/sendpost/fensendpost.vue'),
                meta: {isfan: true }
            },
             // 计划盘点单据
            {
                path: '/stockcheck/stockcheck',
                name: '计划盘点单据',
                component: () => import('../views/stockcheck/stockcheck.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/stockcheck/stockaudit',
                name: '计划盘点审核',
                meta: {keepAlive: true },
                component: () => import('../views/stockcheck/stockaudit.vue'),
            },
            {
                path: '/stockcheck/stockcheckreuslt',
                name: '计划盘点结果',
                meta: {keepAlive: true },
                component: () => import('../views/stockcheck/stockcheckreuslt.vue'),
            },
            {
                path: '/stockcheck/stockcheckpost',
                name: '计划盘点结果',
                component: () => import('../views/stockcheck/stockcheckpost.vue'),
            },
            {
                path: '/stockcheck/stockauditdetail',
                name: '计划盘点审核详情',
                meta: {isfan: true },
                component: () => import('../views/stockcheck/stockauditdetail.vue'),
            },
            {
                path: '/stockcheck/emitcheck',
                name: '盘点数据填报',
                component: () => import('../views/stockcheck/emitcheck.vue'),
                meta: {isfan: true }
            },
            // 查看库存
            {
                path: '/stocklist/stocklist',
                name: '实时库存查看',
                component: () => import('../views/stock/stocklist.vue'),
            },
            {
                path: '/stock/stockpricelist',
                name: '成本价修正',
                component: () => import('../views/stock/stockpricelist.vue'),
            },
            {
                path: '/importstock/importstock',
                name: '库存信息导入',
                component: () => import('../views/stock/importstock.vue'),
                meta:{isfan:true}
            },
            // {
            //     path: '/importstock/importfail',
            //     name: '库存导入失败记录',
            //     component: () => import('../views/stock/importfail.vue'),
            //     meta:{isfan:true}
            // },
            // 盘点计划详情
            {
                path: '/checkDetail/checkDetail',
                name: '盘点计划详情',
                component: () => import('../views/stockcheck/checkDetail.vue'),
                meta: {isfan: true }
            },
            // 报损单管理
            {
                path: '/reduce/reduce',
                name: '报损单管理',
                component: () => import('../views/stockcheck/reduce/reduce.vue'),
                meta: {keepAlive: true }
            },
            // 报损单详情
            {
                path: '/reducedetail/reducedetail',
                name: '报损单详情',
                component: () => import('../views/stockcheck/reduce/reducedetail.vue'),
                meta: {isfan: true }
            },
            // 报溢单管理
            {
                path: '/increase/increase',
                name: '报溢单管理',
                component: () => import('../views/stockcheck/increase/increase.vue'),
                meta: {keepAlive: true }
            },
            // 报溢单详情
            {
                path: '/increasedetail/increasedetail',
                name: '报溢单详情',
                component: () => import('../views/stockcheck/increase/increasedetail.vue'),
                meta: {isfan: true }
                
            },
            // 待发货单据
            {
                path: '/fenpost/fenpost',
                name: '待发货单据',
                component: () => import('../views/post/fenpost/fenpost.vue'),
                meta: {keepAlive: true }
            },
            // 服务订单
            {
                path: '/servepost/servepost',
                name: '待服务订单',
                component: () => import('../views/post/servepost.vue'),
                meta: {keepAlive: true }
            },
            // 服务发货管理
            {
                path: '/servefenpost/servefenpost',
                name: '服务发货管理',
                component: () => import('../views/post/sendpost/servefenpost.vue'),
                meta: {isfan: true }
            },
            // 商品详情
            {
                path: '/goodsdetail/goodsdetail',
                name: '商品详情',
                component: () => import('../views/goodsmanage/goodsdetail.vue'),
                meta: {isfan: true }
            },
             // 导入预览
             {
                path: '/importgoods/importgoods',
                name: '导入预览',
                component: () => import('../views/goodsmanage/importgoods.vue'),
                meta: {isfan: true }
            },
             // 导入预览
             {
                path: '/unimportgoods/unimportgoods',
                name: '导入失败记录',
                component: () => import('../views/goodsmanage/unimportgoods.vue'),
                meta: {isfan: true }
            },
             // 集采导入预览
             {
                path: '/importcent/importcent',
                name: '批量添加集采商品',
                component: () => import('../views/centralized/importcent.vue'),
                meta: {isfan: true }
            },
            // 商品调价
            {
                path: '/checkprice/checkprice',
                name: '商品调价',
                component: () => import('../views/checkprice/checkgoods.vue'),
                meta: {keepAlive: true }
            },
            // 商品调价详情
            {
                path: '/pricedetail/pricedetail',
                name: '商品调价详情',
                component: () => import('../views/checkprice/pricedetail.vue'),
                meta: {isfan: true }
            },
            {
                path: '/word/word',
                name: '敏感词管理',
                component: () => import('../views/word/word.vue'),
            },
            {
                path: '/word/hotword',
                name: '热搜词管理',
                component: () => import('../views/word/hotword.vue'),
            },
            {
                path: '/companyfy/paymenttype',
                name: '收付款类型管理',
                component: () => import('../views/companyfy/paymenttype.vue'),
            },
            {
                path: '/companyfy/payways',
                name: '支付方式管理',
                component: () => import('../views/companyfy/payways.vue'),
            },
            {
                path: '/receipt/receipt',
                name: '收款单管理',
                component: () => import('../views/financial/receipt.vue'),
            },
            {
                path: '/bill/bill',
                name: '付款单管理',
                component: () => import('../views/financial/bill.vue'),
            },
            {
                path: '/incost/incost',
                name: '销售费用收入',
                meta:{keepAlive:true},
                component: () => import('../views/financial/incost.vue'),
            },
            {
                path: '/outcost/outcost',
                name: '销售费用支出',
                meta:{keepAlive:true},
                component: () => import('../views/financial/outcost.vue'),
            },
            {
                path: '/incostdetail/incostdetail',
                name: '销售收入费用单详情',
                meta:{isfan:true,keepAlive:true},
                component: () => import('../views/financial/incostdetail.vue'),
            },
            {
                path: '/outcostdetail/outcostdetail',
                name: '销售支出费用单详情',
                meta:{isfan:true,keepAlive:true},
                component: () => import('../views/financial/outcostdetail.vue'),
            },
            {
                path: '/financial/viewcostdetail',
                name: '费用单详情',
                meta:{isfan:true},
                component: () => import('../views/financial/viewcostdetail.vue'),
            },
            {
                path: '/financial/costdetailinfo',
                name: '费用单详情',
                meta:{isfan:true},
                component: () => import('../views/financial/costdetailinfo.vue'),
            },
            {
                path: '/purchasingcost/incost',
                name: '采购收入费用',
                meta:{keepAlive:true},
                component: () => import('../views/purchasingcost/incost.vue'),
            },
            {
                path: '/purchasingcost/outcost',
                name: '采购支出费用',
                meta:{keepAlive:true},
                component: () => import('../views/purchasingcost/outcost.vue'),
            },
            {
                path: '/purchasingcost/incostdetail',
                name: '采购收入费用单详情',
                meta:{isfan:true,keepAlive:true},
                component: () => import('../views/purchasingcost/incostdetail.vue'),
            },
            {
                path: '/purchasingcost/outcostdetail',
                name: '采购支出费用单详情',
                meta:{isfan:true,keepAlive:true},
                component: () => import('../views/purchasingcost/outcostdetail.vue'),
            },
            {
                path: '/purchasingcost/commoncost',
                name: '日常收支费用',
                component: () => import('../views/purchasingcost/commoncost.vue'),
            },
            {
                path: '/shcostdetail/shcostdetail',
                name: '费用单详情',
                meta:{isfan:true},
                component: () => import('../views/financial/shcostdetail.vue'),
            },
            {
                path: '/emitcostdetail/emitcostdetail',
                name: '费用单编辑',
                meta:{isfan:true},
                component: () => import('../views/financial/emitcostdetail.vue'),
            },
            {
                path: '/verification/verification',
                name: '销售核销',
                meta:{keepAlive:true},
                component: () => import('../views/financial/verification.vue'),
            },
            {
                path: '/financial/verficadetail',
                name: '核销单详情',
                component: () => import('../views/financial/verficadetail.vue'),
                meta:{isfan:true}
            },
            {
                path: '/financial/verficapost',
                name: '核销关联订单',
                component: () => import('../views/financial/verficapost.vue'),
                meta:{isfan:true}
            },
            {
                path: '/financial/verpayinfos',
                name: '核销关联收款单',
                component: () => import('../views/financial/verpayinfos.vue'),
                meta:{isfan:true}
            },
            {
                path: '/checksh/checksh',
                name: '销售异常核销审核',
                meta:{keepAlive:true},
                component: () => import('../views/financial/checksh.vue'),
            },
            {
                path: '/checksh/verficalshdetail',
                name: '核销单审核详情',
                meta:{isfan:true},
                component: () => import('../views/financial/verficalshdetail.vue'),
            },
            {
                path: '/iconinfos/iconinfos',
                name: '图标管理',
                component: () => import('../views/iconinfos/iconinfos.vue'),
            },
            {
                path: '/binding/binding',
                name: '管理员绑定',
                component: () => import('../views/binding/binding.vue'),
                redirect: '/binding/bindbnt',
                children: [
                    {
                        path: '/binding/bindbnt',
                        name: '绑定首页',
                        component: () => import('../views/binding/component/bind.vue'),
                    },
                    {
                        path: '/binding/bindlogin',
                        name: '管理员绑定',
                        component: () => import('../views/binding/component/login.vue'),
                    },
                    {
                        path: '/binding/register',
                        name: '管理员注册',
                        component: () => import('../views/binding/component/register.vue'),
                    },
                    {
                        path: '/binding/submit',
                        name: '管理员申请',
                        component: () => import('../views/binding/component/submit.vue'),
                    },
                ]
            },
            {
                path: '/bindinguser/bindinguser',
                name: '工程人员绑定',
                component: () => import('../views/bindinguser/binding.vue'),
                redirect: '/bindinguser/bindbnt',
                children: [
                    {
                        path: '/bindinguser/bindbnt',
                        name: '绑定首页',
                        component: () => import('../views/bindinguser/component/bind.vue'),
                    },
                    {
                        path: '/bindinguser/bindlogin',
                        name: '工程人员绑定',
                        component: () => import('../views/bindinguser/component/login.vue'),
                    },
                ]
            },
            // 二
            {
                path: '/area/area',
                name: '地区信息管理',
                component: () => import('../views/role/address.vue'),
            },
            {
                path: '/addpost/addpost',
                name: '新增订单',
                meta:{
                    isfan:true
                },
                component: () => import('../views/addpost/addpost.vue'),
            },
            {
                path: '/post/emitpostdetail',
                name: '订单修改',
                meta:{
                    isfan:true,keepAlive: true ,
                },
                component: () => import('../views/post/emitpostdetail.vue'),
            },
            {
                path: '/addpost/emitpost',
                name: '订单详情',
                meta:{
                    isfan:true,
                },
                component: () => import('../views/addpost/emitpost.vue'),
            },
            {
                path: '/addpost/postpay',
                name: '订单支付',
                // meta:{isfan:true},
                component: () => import('../views/addpost/postpay.vue'),
            },
            {
                path: '/addpost/copypost',
                name: '订单复制',
                meta:{isfan:true},
                component: () => import('../views/addpost/copypost.vue'),
            },
            {
                path: '/delist/adddelist',
                name: '退厂单详情',
                meta:{isfan:true},
                component: () => import('../views/delist/adddelist.vue'),
            },
            {
                path: '/delist/delist',
                name: '退厂单填报',
                meta:{keepAlive:true},
                component: () => import('../views/delist/delist.vue'),
            },
            {
                path: '/shipments/retrunsendpost',
                name: '退厂发货单',
                meta:{keepAlive:true},
                component: () => import('../views/shipments/retrunsendpost.vue'),
            },
            {
                path: '/shipments/retrunsenddetail',
                name: '退厂发货单详情',
                meta:{isfan:true},
                component: () => import('../views/shipments/retrunsenddetail.vue'),
            },
            {
                path: '/delist/emitdelist',
                name: '退厂单详情',
                meta:{isfan:true},
                component: () => import('../views/delist/emitdelist.vue'),
            },
            {
                path: '/delist/addnewdelist',
                name: '退货单详情',
                meta:{isfan:true},
                component: () => import('../views/delist/addnewdelist.vue'),
            },
            {
                path: '/delist/addnewdelistx',
                name: '退厂单详情',
                meta:{isfan:true},
                component: () => import('../views/delist/addnewdelistx.vue'),
            },
            {
                path: '/delist/emitnopost',
                name: '退货单详情',
                meta:{isfan:true},
                component: () => import('../views/delist/emitnopost.vue'),
            },
            {
                path: '/delist/shnopost',
                name: '退货单详情',
                meta:{isfan:true},
                component: () => import('../views/delist/shnopost.vue'),
            },
            {
                path: '/delist/emitnewdelist',
                name: '退货单详情',
                meta:{isfan:true},
                component: () => import('../views/delist/emitnewdelist.vue'),
            },
            {
                path: '/companyfy/companyfy',
                name: '费用类型管理',
                component: () => import('../views/companyfy/companyfy.vue'),
            },
            {
                path: '/outpaylist/outpaylist',
                name: '退款日志',
                component: () => import('../views/paylogs/outpaylist.vue'),
            },
            {
                path: '/fenoutpaylist/fenoutpaylist',
                name: '分账退款日志',
                component: () => import('../views/paylogs/fenoutpaylist.vue'),
            },
            {
                path: '/outpaydetail/outpaydetail',
                name: '退款详情',
                meta:{isfan:true},
                component: () => import('../views/paylogs/outpaydetail.vue'),
            },
            // 采购核销
            {
                path: '/purchase/puchaseverificatlist',
                name: '采购核销',
                meta:{keepAlive:true},
                component: () => import('../views/purchase/puchaseverificatlist.vue'),
            },
            {
                path: '/purchase/puschaseverificatdetail',
                name: '采购核销单详情',
                meta:{isfan:true},
                component: () => import('../views/purchase/puschaseverificatdetail.vue'),
            },
            {
                path: '/purchase/puchasepayinfos',
                name: '核销关联付款单',
                meta:{isfan:true},
                component: () => import('../views/purchase/puchasepayinfos.vue'),
            },
            {
                path: '/purchase/puchasepost',
                name: '核销关联采购单',
                meta:{isfan:true},
                component: () => import('../views/purchase/puchasepost.vue'),
            },
            {
                path: '/proxiespost/proxiespost',
                name: '代管订单结算',
                meta:{isfan:true},
                component: () => import('../views/zxpost/proxiespost/proxiespost.vue'),
            },
            {
                path: '/stock/lccgstock',
                name: '临采购单管理',
                meta:{keepAlive:true},
                component: () => import('../views/stock/lccgstock.vue'),
            },
            {
                path: '/salesman/salespost',
                name: '业务员统计',
                meta:{keepAlive:true},
                component: () => import('../views/salesman/salespost.vue'),
            },
            {
                path: '/salesman/commission',
                name: '销售提成审核',
                meta:{keepAlive:true},
                component: () => import('../views/salesman/commission.vue'),
            },
            {
                path: '/salesman/commissionsend',
                name: '业务员提成发放',
                component: () => import('../views/salesman/commissionsend.vue'),
            },
            {
                path: '/salesman/cominfosaudit',
                name: '业务员提成信息',
                meta:{isfan:true},
                component: () => import('../views/salesman/cominfosaudit.vue'),
            },
            {
                path: '/salesman/cominfosview',
                name: '业务员提成信息',
                meta:{isfan:true},
                component: () => import('../views/salesman/cominfosview.vue'),
            },
            {
                path: '/post/postlog',
                name: '订单日志',
                component: () => import('../views/post/postlog.vue'),
            },
            {
                path: '/financial/payablelist',
                name: '应付款信息',
                component: () => import('../views/financial/payablelist.vue'),
            },
            {
                path: '/financial/receivablelist',
                name: '应收款信息',
                component: () => import('../views/financial/receivablelist.vue'),
            },
            {
                path: '/stockcheck/stockdetaillist',
                name: '出入库明细',
                component: () => import('../views/stockcheck/stockdetaillist.vue'),
            },
            {
                path: '/stockcheck/tgstockdetaillist',
                name: '托管出入库明细',
                component: () => import('../views/stockcheck/tgstockdetaillist.vue'),
            },
            {
                path: '/centralized/centralog',
                name: '合作商品操作日志',
                component: () => import('../views/centralized/centralog.vue'),
            },
            {
                path: '/stock/stockpost',
                name: '全部入库单',
                meta:{keepAlive:true},
                component: () => import('../views/stock/stockpost.vue'),
            },
            {
                path: '/stock/storkcgpost',
                name: '采购入库单',
                meta:{keepAlive:true},
                component: () => import('../views/stock/storkcgpost.vue'),
            },
            {
                path: '/stock/stockrepost',
                name: '退货入库单',
                meta:{keepAlive:true},
                component: () => import('../views/stock/stockrepost.vue'),
            },
            {
                path: '/zxpost/retrunpost',
                name: '装企退货信息',
                meta:{keepAlive:true},
                component: () => import('../views/zxpost/retrunpost.vue'),
            },
            {
                path: '/logs/logs',
                name: '管理员日志',
                component: () => import('../views/log/logs.vue'),
            },
            {
                path: '/stock/stockstorage',
                name: '库位信息管理',
                component: () => import('../views/stock/stockstorage.vue'),
            },
            {
                path: '/fenpost/lcfenpost',
                name: '临采发货信息',
                meta:{keepAlive:true},
                component: () => import('../views/post/fenpost/lcfenpost.vue'),
            },
            {
                path: '/buyinfo/buyinfolist',
                name: '采购信息管理',
                meta:{keepAlive:true},
                component: () => import('../views/buyinfo/buyinfolist.vue'),
            },
            {
                path: '/supplier/supbuyinfolist',
                name: '供货价格管理',
                meta:{keepAlive:true},
                component: () => import('../views/supplier/supbuyinfolist.vue'),
            },
            {
                path: '/zxstock/supbuyinfolist',
                name: '供货价格管理',
                meta:{keepAlive:true},
                component: () => import('../views/zxstock/supbuyinfolist.vue'),
            },
            {
                path: '/payables/payableslist',
                name: '采购应付审核',
                meta:{keepAlive:true},
                component: () => import('../views/payables/payableslist.vue'),
            },
            {
                path: '/buyinfo/buyinfodshlist',
                name: '自营采购审核',
                meta:{keepAlive:true},
                component: () => import('../views/buyinfo/buyinfodshlist.vue'),
            },
            {
                path: '/buyinfo/buyinfoblocklist',
                name: '自营商品价格审核',
                meta:{keepAlive:true},
                component: () => import('../views/buyinfo/buyinfoblocklist.vue'),
            },
            {
                path: '/buyinfo/buyinfodrklist',
                name: '采购信息入库',
                meta:{keepAlive:true},
                component: () => import('../views/buyinfo/buyinfodrklist.vue'),
            },
            {
                path: '/buyinfo/buyinfodetail',
                name: '新增采购信息',
                meta:{isfan:true},
                component: () => import('../views/buyinfo/buyinfodetail.vue'),
            },
            {
                path: '/buyinfo/emitbuyinfodetail',
                name: '编辑采购信息',
                meta:{isfan:true},
                component: () => import('../views/buyinfo/emitbuyinfodetail.vue'),
            },
            {
                path: '/buyinfo/viewbuyinfodetail',
                name: '采购信息详情',
                meta:{isfan:true},
                component: () => import('../views/buyinfo/viewbuyinfodetail.vue'),
            },
            {
                path: '/buyinfo/shbuyinfodetail',
                name: '采购信息审核',
                meta:{isfan:true},
                component: () => import('../views/buyinfo/shbuyinfodetail.vue'),
            },
            {
                path: '/buyinfo/blockbuyinfodetail',
                name: '自营商品价格审核',
                meta:{isfan:true},
                component: () => import('../views/buyinfo/blockbuyinfodetail.vue'),
            },
            {
                path: '/buyinfo/rkbuyinfodetail',
                name: '采购信息入库',
                meta:{isfan:true},
                component: () => import('../views/buyinfo/rkbuyinfodetail.vue'),
            },
            {
                path: '/buyinfo/rkviewbuyinfodetail',
                name: '采购信息详情',
                meta:{isfan:true},
                component: () => import('../views/buyinfo/rkviewbuyinfodetail.vue'),
            },
            {
                path: '/stock/stocksuredetail',
                name: '价格审核详情',
                meta:{isfan:true,keepAlive:true},
                component: () => import('../views/stock/stocksure/stocksuredetail.vue'),
            },
            {
                path: '/supplier/supbuyinfodetail',
                name: '供货单价格详情',
                meta:{isfan:true},
                component: () => import('../views/supplier/supbuyinfodetail.vue'),
            },
            {
                path: '/zxstock/supbuyinfodetail',
                name: '供货单价格详情',
                meta:{isfan:true},
                component: () => import('../views/zxstock/supbuyinfodetail.vue'),
            },
            {
                path: '/payables/payablesdetail',
                name: '应付款单审核',
                meta:{isfan:true},
                component: () => import('../views/payables/payablesdetail.vue'),
            },
            {
                path: '/salesman/performancelist',
                name: '业绩统计',
                meta:{keepAlive:true},
                component: () => import('../views/salesman/performancelist.vue'),
            },
            {
                path: '/salesman/incostlist',
                name: '应收款项统计',
                meta:{keepAlive:true},
                component: () => import('../views/salesman/incostlist.vue'),
            },
            {
                path: '/salesman/comdata',
                name: '提成统计',
                meta:{keepAlive:true},
                component: () => import('../views/salesman/comdata.vue'),
            },
            {
                path: '/salesman/tichengdetail',
                name: '创建提成信息',
                meta:{keepAlive:true},
                component: () => import('../views/salesman/tichengdetail.vue'),
            },
            {
                path: '/client/promoter',
                name: '推广大使审核',
                component: () => import('../views/client/promoter.vue'),
            },
            {
                path: '/client/promoterlist',
                name: '推广大使列表',
                component: () => import('../views/client/promoterlist.vue'),
            },
            {
                path: '/client/promoterdata',
                name: '佣金统计',
                component: () => import('../views/client/promoterdata.vue'),
            },
            {
                path: '/client/promoterpost',
                name: '销售佣金审核',
                meta:{keepAlive:true},
                component: () => import('../views/client/promoterpost.vue'),
            },
            {
                path: '/client/yshpromoterpost',
                name: '销售佣金统计',
                meta:{keepAlive:true},
                component: () => import('../views/client/yshpromoterpost.vue'),
            },
            {
                path: '/statistics/cgstatistics',
                name: '采购成本明细',
                meta:{keepAlive:true},
                component: () => import('../views/statistics/cgstatistics.vue'),
            },
            {
                path: '/statistics/yjscgstatistics',
                name: '已结算采购信息',
                meta:{keepAlive:true},
                component: () => import('../views/statistics/yjscgstatistics.vue'),
            },
            {
                path: '/statistics/yfstatistics',
                name: '应付明细',
                meta:{keepAlive:true},
                component: () => import('../views/statistics/yfstatistics.vue'),
            },
            {
                path: '/statistics/yfmxstatistics',
                name: '应付明细汇总',
                meta:{keepAlive:true},
                component: () => import('../views/statistics/yfmxstatistics.vue'),
            },
            {
                path: '/statistics/xsmlstatistics',
                name: '销售毛利',
                meta:{keepAlive:true},
                component: () => import('../views/statistics/xsmlstatistics.vue'),
            },
            {
                path: '/statistics/ysstatistics',
                name: '应收数据查询',
                meta:{keepAlive:true},
                component: () => import('../views/statistics/ysstatistics.vue'),
            },
            {
                path: '/statistics/xsystatistics',
                name: '销售员应收',
                meta:{keepAlive:true},
                component: () => import('../views/statistics/xsystatistics.vue'),
            },
            {
                path: '/statistics/xsysrstatistics',
                name: '销售员销售统计',
                meta:{keepAlive:true},
                component: () => import('../views/statistics/xsysrstatistics.vue'),
            },
            {
                path: '/statistics/spdlstatistics',
                name: '商品统计',
                meta:{keepAlive:true},
                component: () => import('../views/statistics/spdlstatistics.vue'),
            },
            {
                path: '/statistics/cysstatistics',
                name: '供应商统计',
                meta:{keepAlive:true},
                component: () => import('../views/statistics/cysstatistics.vue'),
            },
            {
                path: '/statistics/xsfyszstatistics',
                name: '销售费用收支',
                meta:{keepAlive:true},
                component: () => import('../views/statistics/xsfyszstatistics.vue'),
            },
            {
                path: '/account/accountlist',
                name: '关账管理',
                component: () => import('../views/account/accountlist.vue'),
            },
            {
                path: '/account/accountdatas',
                name: '补充期初余额数据',
                meta:{isfan:true},
                component: () => import('../views/account/accountdatas.vue'),
            },
            {
                path: '/salesman/shtichenglist',
                name: '销售提成审核',
                meta:{keepAlive:true},
                component: () => import('../views/salesman/shtichenglist.vue'),
            },
            {
                path: '/salesman/shtichengdetail',
                name: '提成信息审核详情',
                meta:{isfan:true},
                component: () => import('../views/salesman/shtichengdetail.vue'),
            },
            {
                path: '/salesman/alltcpost',
                name: '全部提成信息',
                component: () => import('../views/salesman/alltcpost.vue'),
            },
            {
                path: '/statistics/xsfystatistics',
                name: '销售费用',
                meta:{keepAlive:true},
                component: () => import('../views/statistics/xsfystatistics.vue'),
            },
            {
                path: '/statistics/cgfystatistics',
                name: '采购费用',
                meta:{keepAlive:true},
                component: () => import('../views/statistics/cgfystatistics.vue'),
            },
            {
                path: '/statistics/xsfystatisticscont',
                name: '销售费用汇总',
                meta:{keepAlive:true},
                component: () => import('../views/statistics/xsfystatisticscont.vue'),
            },
            {
                path: '/statistics/xsysstatisticscont',
                name: '销售应收汇总',
                meta:{keepAlive:true},
                component: () => import('../views/statistics/xsysstatisticscont.vue'),
            },
            {
                path: '/statistics/cgfystatisticscont',
                name: '采购费用汇总',
                meta:{keepAlive:true},
                component: () => import('../views/statistics/cgfystatisticscont.vue'),
            },
            {
                path: '/sendinfos/sendlist',
                name: '配送单信息',
                meta:{keepAlive:true},
                component: () => import('../views/sendinfos/sendlist.vue'),
            },
            {
                path: '/client/shpromoterpost',
                name: '销售佣金审核',
                meta:{keepAlive:true},
                component: () => import('../views/client/shpromoterpost.vue'),
            },
            {
                path: '/allotpost/allotpostlist',
                name: '仓库调拨管理',
                meta:{keepAlive:true},
                component: () => import('../views/allotpost/allotpostlist.vue'),
            },
            {
                path: '/allotpost/allotpostdshlist',
                name: '仓库调拨审核',
                meta:{keepAlive:true},
                component: () => import('../views/allotpost/allotpostdshlist.vue'),
            },
            {
                path: '/allotpost/allotpostdqrlist',
                name: '仓库调拨确认',
                meta:{keepAlive:true},
                component: () => import('../views/allotpost/allotpostdqrlist.vue'),
            },
            {
                path: '/allotpost/dqrallotpostdetail',
                name: '仓库调拨确认详情',
                meta:{isfan:true},
                component: () => import('../views/allotpost/dqrallotpostdetail.vue'),
            },
            {
                path: '/allotpost/allotpostdetail',
                name: '仓库调拨详情',
                meta:{isfan:true},
                component: () => import('../views/allotpost/allotpostdetail.vue'),
            },
            {
                path: '/allotpost/dshallotpostdetail',
                name: '仓库调拨审核详情',
                meta:{isfan:true},
                component: () => import('../views/allotpost/dshallotpostdetail.vue'),
            },
            {
                path: '/allotpost/emitallotpostdetail',
                name: '仓库调拨修改信息',
                meta:{isfan:true},
                component: () => import('../views/allotpost/emitallotpostdetail.vue'),
            },
            {
                path: '/allotpost/viewallotpostdetail',
                name: '仓库调拨详情',
                meta:{isfan:true},
                component: () => import('../views/allotpost/viewallotpostdetail.vue'),
            },
            {
                path: '/driver/drivercost',
                name: '司机费用信息',
                meta: {keepAlive:true},
                component: () => import('../views/driver/drivercost.vue'),
            },
            {
                path: '/driver/driverdshcost',
                name: '司机费用审核',
                meta: {keepAlive:true},
                component: () => import('../views/driver/driverdshcost.vue'),
            },
            {
                path: '/driver/driverdshcostlist',
                name: '司机费用审核',
                meta: {keepAlive:true},
                component: () => import('../views/driver/driverdshcostlist.vue'),
            },
            {
                path: '/driver/dricercostdetail',
                name: '司机费用详情',
                meta:{isfan:true},
                component: () => import('../views/driver/dricercostdetail.vue'),
            },
            {
                path: '/driver/costdshdetail',
                name: '司机费用审核详情',
                meta:{isfan:true},
                component: () => import('../views/driver/costdshdetail.vue'),
            },
            {
                path: '/zxpost/daiguanpost',
                name: '托管订单管理',
                component: () => import('../views/zxpost/daiguanpost.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/zxpost/jicaipost',
                name: '集采订单统计',
                component: () => import('../views/zxpost/jicaipost.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/zxpost/jcpostdetail',
                name: '订单详情',
                component: () => import('../views/zxpost/postdetail/jcpostdetail.vue'),
                meta: {isfan: true }
            },
            {
                path: '/zxstock/stockdetaillist',
                name: '代管出入库明细',
                component: () => import('../views/zxstock/stockdetaillist.vue'),
                meta: {isfan: true }
            },
            {
                path: '/zxstock/tgstocklist',
                name: '代管出库单统计',
                component: () => import('../views/zxstock/tgstocklist.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/zxstock/dgpdinfos',
                name: '代管盘点信息',
                component: () => import('../views/zxstock/dgpdinfos.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/zxstock/zxstockcheckdetail',
                name: '代管盘点信息详情',
                component: () => import('../views/zxstock/zxstockcheckdetail.vue'),
                meta: {isfan: true }
            },
            {
                path: '/lcapply/lcpostlist',
                name: '临采修改申请',
                component: () => import('../views/lcapply/lcpostlist.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/lcapply/lcpostdetail',
                name: '临采修改详情',
                component: () => import('../views/lcapply/lcpostdetail.vue'),
                meta: {isfan: true }
            },
            {
                path: '/lcapply/lcnewpostlist',
                name: '临采申请列表',
                component: () => import('../views/lcapply/lcnewpostlist.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/lcapply/lcdshpostlist',
                name: '临采申请审核',
                component: () => import('../views/lcapply/lcdshpostlist.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/lcapply/updatelcpostdetail',
                name: '临采申请修改',
                component: () => import('../views/lcapply/updatelcpostdetail.vue'),
                meta: {isfan: true }
            },
            {
                path: '/lcapply/shlcpostdetail',
                name: '临采申请审核详情',
                component: () => import('../views/lcapply/shlcpostdetail.vue'),
                meta: {isfan: true }
            },
            {
                path: '/lcapply/viwelcpostdetail',
                name: '临采申请详情',
                component: () => import('../views/lcapply/viwelcpostdetail.vue'),
                meta: {isfan: true }
            },
            {
                path: '/fenpost/dgfenpost',
                name: '代管配送单',
                component: () => import('../views/post/fenpost/dgfenpost.vue'),
                meta: {keepAlive: true }
            },
            {
                path: '/sendpost/dgsendpost',
                name: '代管临采发货',
                component: () => import('../views/post/sendpost/dgsendpost.vue'),
                meta: {isfan: true }
            },
            {
                path: '/stock/importprice',
                name: '批量修正成本价',
                component: () => import('../views/stock/importprice.vue'),
                meta: {isfan: true }
            },
            {
                path: '/project/servicers',
                name: '工程人员管理',
                component: () => import('../views/project/servicers.vue'),
            },
            {
                path: '/project/serviceconfig',
                name: '预约服务管理',
                component: () => import('../views/project/serviceconfig.vue'),
            },
            {
                path: '/project/servepost',
                name: '预约订单管理',
                component: () => import('../views/project/servepost.vue'),
            },
            {
                path: '/project/zxservepost',
                name: '预约订单管理',
                component: () => import('../views/project/zxservepost.vue'),
            },
            {
                path: '/project/ddsservepost',
                name: '预约单指派',
                component: () => import('../views/project/ddsservepost.vue'),
            },
            {
                path: '/project/vrservepost',
                name: 'VR管理',
                component: () => import('../views/project/vrservepost.vue'),
            },
            {
                path: '/configuration/threeconfig',
                name: '第三方配置',
                component: () => import('../views/configuration/threeconfig.vue'),
            },
            {
                path: '/log/viewlog',
                name: '更新日志管理',
                component: () => import('../views/log/viewlog.vue'),
            },
            {
                path: '/log/helpbookemit',
                name: '帮助文档管理',
                component: () => import('../views/log/helpbookemit.vue'),
            },
            {
                path: '/postdata/zxpostcuntdata',
                name: '装修公司订单合计',
                meta: {keepAlive: true },
                component: () => import('../views/postdata/zxpostdata.vue'),
            },
            {
                path: '/statistics/goodstatistics',
                name: '商品销量统计',
                component: () => import('../views/statistics/goodstatistics.vue'),
            },
            {
                path: '/statistics/goodmoneystatistics',
                name: '商品销售额统计',
                component: () => import('../views/statistics/goodmoneystatistics.vue'),
            },
            {
                path: '/statistics/goodusestatistics',
                name: '消费用户统计',
                component: () => import('../views/statistics/goodusestatistics.vue'),
            },
        ]
    },
    {
        path: '/pay/pay',
        name: '订单结算',
        meta: {
            title: '材搬配结算页面'
        },
        component: () => import('../views/zxpost/payinfo.vue')
    },
    {
        path: '/addpost/pay',
        name: '订单结算',
        meta: {
            title: '材搬配结算页面'
        },
        component: () => import('../views/addpost/paydetail.vue')
    },
    {
        path: '/test/test',
        name: '订单结算',
        meta: {
            title: '材搬配结算页面'
        },
        component: () => import('../views/binding/test.vue')
    },
    {
        path: '/question/question',
        name: '提交工单',
        meta: {
            title: '材搬配提交工单'
        },
        component: () => import('../views/questions/submitquestion.vue')
    },
    {
        path: '/log/helpbook',
        meta: {
            title: '材搬配帮助文档'
        },
        name: '帮助文档管理',
        component: () => import('../views/log/helpbook.vue'),
    },
]

const router = new VueRouter({
    //mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
